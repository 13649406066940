import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from './auth/Login';
import {getUsuario} from './auth';
import { BillList, BillCrud } from './bill-list/Bill';

const Routes = () => (  
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={BillList} />
      <RouteAuthorized path="/BillCrud" component={BillCrud} />
      <Route path="/Login" component={Login} />      
    </Switch>
  </BrowserRouter>
);

class RouteAuthorized extends Component {
    render() {

        var user = getUsuario();

        if(user == null)
        { 
            localStorage.setItem('returnUrl', this.props.path);
            return <Redirect to="/login" />;
        }

        return <Switch><Route {...this.props} /></Switch>;
    }
}

export default Routes;