let listCallBack = [];

window.onloadCallbackRecaptcha = function (e) {
    if(document.querySelector('#g-recaptcha-boleto')){
        window.grecaptcha.render('g-recaptcha-boleto', {
            'sitekey' : '6LeqOngUAAAAABaDDmys49HWmpLFzVTXaG2YqbiV',
            'callback' : function (hash) {
                localStorage.setItem('g-recaptcha-verify', hash);
                listCallBack.forEach(function(func, index){
                    if(func) func(hash);
                });
            },
            'hl': 'pt-BR',
            'expired-callback': function(){
                localStorage.removeItem('g-recaptcha-verify');
                listCallBack.forEach(function(func, index){
                    if(func) func(null);
                });
            }
        });
    }
}

let recaptcha = {
    getHashVerify: function (){
        return localStorage.getItem('g-recaptcha-verify');
    },    
    addCallBackVerify: function (func){
        listCallBack.push(func);
    },    
    reset: function(){
        window.grecaptcha.reset();
        localStorage.removeItem('g-recaptcha-verify');
        listCallBack.forEach(function(func, index){
            if(func) func(null);
        });    
    }    
}

export { recaptcha }