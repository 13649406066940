import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/storage';
//import '@firebase/firestore';
import '@firebase/database';

//import firebaseui from 'firebaseui';

var config = {
    apiKey: "AIzaSyDbhTBVnzRpyMSsaak8k6HyDX_s3vNpOcM",
    authDomain: "pt-mdo.firebaseapp.com",
    databaseURL: "https://pt-mdo.firebaseio.com",
    projectId: "pt-mdo",
    storageBucket: "pt-mdo.appspot.com",
    messagingSenderId: "81977955399"
  };

  firebase.initializeApp(config);

  const auth = firebase.auth();
  const storage = firebase.storage();  
  //const firestore = firebase.firestore();
  const database = firebase.database();  

  //export { auth, storage, firestore, firebase as default }
  export { auth, storage, database, firebase as default }