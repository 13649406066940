import React, { Component } from 'react';
import {auth} from '../firebase';
import { getUsuario, setUsuario } from './index';
import toastr from  'toastr';
import Menu from '../nav-bar/Menu'

class Login extends Component {   

    constructor(props){
        super(props);        
        this.state = {
            user: null,
            loging: false,
            textLogin: 'fazer login',
            textLogoff: 'logoff'
        };
    }
    
    componentDidMount(){
        let user = getUsuario();
        if(user != null){
            this.refs.email.value = user.user.email;
            this.setState({user});
            if(this.props.onChange){
                this.props.onChange(user);
            }            
        }
        auth.onAuthStateChanged(eChange=>{
            //console.log('login:eChange', eChange);
        }, 
        eError=>{
            toastr.error('Erro em mudança de login', 'bill-login');
        }, 
        eComplete=>{
            //console.log('login:eComplete', eComplete);
        });        
    }

    login(){
        
        this.setState({loging: true, textLogin: 'fazendo login'});

        auth
            .signInWithEmailAndPassword(this.refs.email.value, this.refs.senha.value)
            .then(user=>{
                toastr.success('login realizado com sucesso!', 'bill-login');
                this.setState({user, loging: false, textLogin: 'fazer login'});
                setUsuario(user);
                let returnUrl = localStorage.getItem('returnUrl');
                if(returnUrl){
                    localStorage.removeItem('returnUrl');
                    document.location.href = returnUrl;
                }
            })
            .catch(error => {                
                toastr.error('Erro ao realizar login', 'bill-login');
                this.refs.senha.value = '';
                this.setState({loging: false, textLogin: 'fazer login'});
            });
    }

    logoff(){
        this.setState({loging: true, textLogoff: 'fazendo logoff'});
        auth
        .signOut()
        .then(()=> {            
            toastr.success('logoff realizado com sucesso!', 'bill-logoff');
            this.setState({user:null,loging: false, textLogoff: 'logoff'});
            setUsuario(null);
            if(this.props.onChange){
                this.props.onChange(null);
            } 
        })
        .catch(error=> {            
            this.setState({loging: false, textLogoff: 'logoff'});
            toastr.error('Erro ao fazer logoff', 'bill-logoff');
        });        
    }

    getClassLoad(className){       

        if(this.state.loging) {
            return "fa fa-spinner spn-running";            
        }
        else{
            return className;
        }
    } 

    render() {
        return (
            <div className="card">
                <div className="card-header alert-primary">
                <img src="logo_mini.png" alt="Logo - Axiosi"></img><h5>[Licença-MDO-L4] Login</h5>
                </div> 
                <div className="card-body">
                    <Menu />
                    <div className="">
                        <div className="form-group">
                            <label htmlFor="email">Email</label>                        
                            <input type="email" ref="email" disabled={this.state.user} className="form-control" id="email" name="email" placeholder="email" />
                        </div>
                    </div>
                    <div className="" hidden={this.state.user}>                        
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input type="password" ref="senha" className="form-control" id="password" name="password" placeholder="password" onKeyUp={e=> { if(e.keyCode===13){this.login()} }} />
                        </div>
                    </div>
                    <div className="">
                        <button className="btn btn-primary" disabled={this.state.loging} onClick={e=>{this.login()}} hidden={this.state.user}><i className={this.getClassLoad("fa fa-key")}></i>{this.state.textLogin}</button>
                        <button className="btn btn-danger" onClick={e=>{this.logoff()}} hidden={!this.state.user} ><i className={this.getClassLoad("fa fa-close")}></i> {this.state.textLogoff}</button>                
                    </div>
                </div>              
            </div>
        );
    }
}

export default Login;